<template>
    <div class="organization-form-content">
        <h4>Organization</h4>
        <div class="organization-form-field">
            <span>Organization Code</span>
            <input type="text" class="organization-form-field-textbox"
                v-model="organizations[0].organizationCode"
                />
        </div>
        <div class="organization-form-field">
            <span>Trusted for Receiving</span>
            <input type="checkbox" class="organization-form-field-checkbox" 
                v-model="organizations[0].trustedForReceiving"
                />
        </div>
    </div>
</template>

<style scoped>
.organization-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.organization-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.organization-form-field span {
    text-align: left;
    width: 30%;
}

.organization-form-field-checkbox {
    width: auto;    
}

.organization-form-field-textbox {
    width: 50%;    
}
</style>

<script lang="js" src="./OrganizationForm.js" />
