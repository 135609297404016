<template>
    <div class="uom-form-content">
        <h4>{{title}}</h4>
        <div class="uom-form-field">
            <span>UOM Code</span>
            <input type="text" class="uom-form-field-textbox"
                v-model="uoms[0].uomCode"
                />
        </div>
        <div class="uom-form-field">
            <span>GTIN</span>
            <input type="text" class="uom-form-field-textbox"
                v-model="uoms[0].gtin"
                />
        </div>
        <div class="uom-form-field">
            <span>Multiplier</span>
            <input type="text" class="uom-form-field-textbox"
                v-model="uoms[0].multiplier"
                />
        </div>
        <div class="uom-form-field">
            <span>Description</span>
            <input type="text" class="uom-form-field-textbox"
                v-model="uoms[0].description[0].shortDescription"
                />
        </div>
        <div class="uom-form-field">
            <span>Default</span>
            <input type="checkbox" class="uom-form-field-checkbox" 
                v-model="uoms[0].default"
                />
        </div>
        <div class="uom-form-field">
            <span>Stocking</span>
            <input type="checkbox" class="uom-form-field-checkbox" 
                v-model="uoms[0].stocking"
                />
        </div>        
    </div>
</template>

<style scoped>
.uom-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.uom-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.uom-form-field span {
    text-align: left;
    width: 30%;
}

.uom-form-field-checkbox {
    width: auto;    
}

.uom-form-field-textbox {
    width: 50%;    
}
</style>

<script lang="js" src="./UomForm.js" />
