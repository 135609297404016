<template>
    <div class="master-data-gen">
        <h3>Create Master Data</h3>
        <div class="master-gen-content">
            <div class="master-gen-content-type">
                <h3>Select CDM Type:</h3>
                <CdmTypes 
                    @onCdmTypeSelected="onCdmTypeSelected"/>
            </div>
            <div class="master-gen-content-form">
                <h3>Fill in information:</h3>
                <div class="master-gen-content-form-commands">
                    <button @click="loadCdmModel()">Load CDM Model</button>
                </div>
                <VendorForm v-if="selectedCdmType == 'VENDOR'" :vendor="newVendor" />
                <ManufacturerForm v-if="selectedCdmType == 'MANUFACTURER'" :manufacturer="newManufacturer" />
                <CustomerForm v-if="selectedCdmType == 'CUSTOMER'" :customer="newCustomer" />
                <CombinedItemForm v-if="selectedCdmType == 'ITEM'" :itemData="newItem" />
            </div>
            <div class="master-gen-content-preview">
                <h3>Preview:</h3>
                <div class="master-gen-content-preview-field">{{status}}</div>
                <div class="master-gen-content-preview-field">{{response}}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .master-gen-content {
        display: flex;
        flex-direction: row;
        
    }

    .master-gen-content-type {
        padding: 1em;
        flex-grow: 1;
    }

    .master-gen-content-form {
        padding: 1em;
        flex-grow: 2;
    }

    .master-gen-content-commands {
        display: flex;
        flex-direction: row;
    }

    .master-gen-content-preview {
        padding: 1em;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .master-gen-content-preview-field {
        display: flex;
        padding: 1em;
        width: 20em;
    }
</style>

<script lang="js" src="./MasterDataGenerator.js" />