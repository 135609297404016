export default {
    name: "ManufacturerForm",
    props: [
        "manufacturer"
    ],
    data() {
        return {
        };
    },
    methods: {
    }
}