
export default {
    name: "ManufacturerItemForm",
    components: {
    },
    props: [
        "item"
    ],
    methods: {
    }
}