import { cdmTypes } from "../../api/master-data"

export default {
    name: "CdmTypes",
    data() {
        return {
            selectedIndex: -1
        };
    },
    computed: {
        types: function() {
            const types = [];
            const keys = Object.keys(cdmTypes);
            for (let i=0; i < keys.length; i++) {
                types.push(cdmTypes[keys[i]]);
            }       
            return types;
        }
    },
    methods: {
        selectType(type, index) {
            this.selectedIndex = index;
            this.$emit('onCdmTypeSelected', type);
        }
    }
}