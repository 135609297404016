<template>
    <div class="customer-form-content">
        <h3>New customer</h3>
        <div class="customer-form-field">
            <span>System Managed</span>
            <select
                class="customer-form-field-select"
                v-model="customer.systemManagedType"
                >
                <option disabled value="">Please Select One</option>
                <option value="TECSYS">TECSYS</option>
                <option value="ERP">ERP</option>
            </select>
        </div>
        <div class="customer-form-field">
            <span>Customer Code</span>
            <input type="text" class="customer-form-field-textbox"
                v-model="customer.customerCode"
                />
        </div>
        <div class="customer-form-field">
            <span>External Reference</span>
            <input type="text" class="customer-form-field-textbox"
                v-model="customer.externalReference"
                />
        </div>
        <div class="customer-form-field">
            <span>Fax Number</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.faxNumber"
                />
        </div>
        <div class="customer-form-field">
            <span>Phone Number</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.phoneNumber1"
                />
        </div>
        <div class="customer-form-field">
            <span>Phone Number 2</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.phoneNumber2"
                />
        </div>
        <div class="customer-form-field">
            <span>Ship To Customer Code</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.shipToCustomerCode"
                />
        </div>
        <div class="customer-form-field">
            <span>Via Code</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.viaCode"
                />
        </div>
        <div class="customer-form-field">
            <span>Warehouse Code</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.warehouseCode"
                />
        </div>
        <div class="customer-form-field">
            <span>Description</span>
            <input type="text" class="customer-form-field-textbox" 
                v-model="customer.description[0].shortDescription"
                />
        </div>
        <div class="customer-form-field">
            <span>Barcode Tracked</span>
            <input type="checkbox" class="customer-form-field-checkbox" 
                v-model="customer.barcodeTracked"
                />
        </div>
        <div class="customer-form-field">
            <span>License Verification Required</span>
            <input type="checkbox" class="customer-form-field-checkbox" 
                v-model="customer.licenseVerificationRequired"
                />
        </div>        
        <div class="customer-form-field">
            <span>Receiving Site Location</span>
            <input type="checkbox" class="customer-form-field-checkbox" 
                v-model="customer.receivingSiteLocation"
                />
        </div>        
        <div class="customer-form-field">
            <span>RFID Tracked</span>
            <input type="checkbox" class="customer-form-field-checkbox" 
                v-model="customer.rfidTracked"
                />
        </div>        
        <div class="customer-form-field">
            <span>Supply Location</span>
            <input type="checkbox" class="customer-form-field-checkbox" 
                v-model="customer.supplyLocation"
                />
        </div>        
        <AddressForm :address="customer.address" />
        <OrganizationForm :organizations="customer.organization" />
        <GlobalLocationNumberForm :globalLocationNumbers="customer.globalLocationNumber" />
    </div>    
</template>

<style scoped>
.customer-form-content {
    display: flex;
    flex-direction: column;
}

.customer-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.customer-form-field span {
    width: 30%;
    text-align: left;
}

.customer-form-field-checkbox {
    width: auto;    
}

.customer-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./CustomerForm.js" />