<template>
    <div class="manufacturer-form-content">
        <h3>New manufacturer</h3>
        <div class="manufacturer-form-field">
            <span>System Managed</span>
            <select
                class="manufacturer-form-field-select"
                v-model="manufacturer.systemManagedType"
                >
                <option disabled value="">Please Select One</option>
                <option value="TECSYS">TECSYS</option>
                <option value="ERP">ERP</option>
            </select>
        </div>
        <div class="manufacturer-form-field">
            <span>Code</span>
            <input type="text" class="manufacturer-form-field-textbox"
                v-model="manufacturer.manufacturerCode"
                />
        </div>
        <div class="manufacturer-form-field">
            <span>Labeler Identification Code</span>
            <input type="text" class="manufacturer-form-field-textbox"
                v-model="manufacturer.labelerIdentificationCode"
                />
        </div>        
        <div class="manufacturer-form-field">
            <span>Description</span>
            <input type="text" class="manufacturer-form-field-textbox" 
                v-model="manufacturer.description[0].shortDescription"
                />
        </div>
    </div>    
</template>

<style scoped>
.manufacturer-form-content {
    display: flex;
    flex-direction: column;
}

.manufacturer-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.manufacturer-form-field span {
    width: 30%;
    text-align: left;
}

.manufacturer-form-field-checkbox {
    width: auto;    
}

.manufacturer-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./ManufacturerForm.js" />