<template>
    <div class="vendor-form-content">
        <h3>New Vendor</h3>
        <div class="vendor-form-field">
            <span>System Managed</span>
            <select
                class="vendor-form-field-select"
                v-model="vendor.systemManagedType"
                >
                <option disabled value="">Please Select One</option>
                <option value="TECSYS">TECSYS</option>
                <option value="ERP">ERP</option>
            </select>
        </div>
        <div class="vendor-form-field">
            <span>Vendor Code</span>
            <input type="text" class="vendor-form-field-textbox" 
                v-model="vendor.vendorCode"
                />
        </div>
        <div class="vendor-form-field">
            <span>Contact</span>
            <input type="text" class="vendor-form-field-textbox" 
                v-model="vendor.contact"
                />
        </div>
        <div class="vendor-form-field">
            <span>License Verification Required</span>
            <input type="checkbox" class="vendor-form-field-checkbox" 
                v-model="vendor.licenseVerificationRequired"
                />
        </div>
        <div class="vendor-form-field">
            <span>Supply Site Provider</span>
            <input type="checkbox" class="vendor-form-field-checkbox" 
                v-model="vendor.supplySiteProvider"
                />
        </div>
        <div class="vendor-form-field">
            <span>Description</span>
            <input type="text" class="vendor-form-field-textbox" 
                v-model="vendor.description[0].shortDescription"
                />
        </div>        
        <OrganizationForm :organizations="vendor.organization" />
        <VendorLocationForm :locations="vendor.location" />
    </div>    
</template>

<style scoped>
.vendor-form-content {
    display: flex;
    flex-direction: column;
}

.vendor-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.vendor-form-field span {
    width: 30%;
    text-align: left;
}

.vendor-form-field-checkbox {
    width: auto;    
}

.vendor-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./VendorForm.js" />