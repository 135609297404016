<template>
    <div class="global-location-form-content">
        <h4>Global Location Form</h4>
        <div class="global-location-form-field">
            <span>Global Location Number</span>
            <input type="text" class="global-location-form-field-textbox"
                v-model="globalLocationNumbers[0].globalLocationNumber"
                />
        </div>
        <div class="global-location-form-field">
            <span>Default</span>
            <input type="checkbox" class="global-location-form-field-checkbox" 
                v-model="globalLocationNumbers[0].default"
                />
        </div>
    </div>
</template>

<style scoped>
.global-location-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.global-location-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.global-location-form-field span {
    text-align: left;
    width: 30%;
}

.global-location-form-field-checkbox {
    width: auto;    
}

.global-location-form-field-textbox {
    width: 50%;    
}
</style>

<script lang="js" src="./GlobalLocationNumberForm.js" />
