import AddressForm from './AddressForm.vue';

export default {
    name: "VendorLocationForm",
    components: {
        AddressForm
    },
    props: [
        'locations'
    ],
    methods: {
    }
}