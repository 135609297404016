<template>
    <div class="manufacturer-item-form-content">
        <h3>Manufacturer Item Details</h3>
        <div class="manufacturer-item-form-field">
            <span>Manufacturer Code</span>
            <input type="text" class="manufacturer-item-form-field-textbox"
                v-model="item.manufacturerCode"
                />
        </div>
        <div class="manufacturer-item-form-field">
            <span>Manufacturer Item Code</span>
            <input type="text" class="manufacturer-item-form-field-textbox"
                v-model="item.manufacturerItemCode"
                />
        </div>
        <div class="manufacturer-item-form-field">
            <span>Description</span>
            <input type="text" class="manufacturer-item-form-field-textbox" 
                v-model="item.description[0].shortDescription"
                />
        </div>
        <div class="manufacturer-item-form-field">
            <span>Expiry Date Provided</span>
            <input type="checkbox" class="manufacturer-item-form-field-checkbox" 
                v-model="item.expiryDateProvided"
                />
        </div>        
        <div class="manufacturer-item-form-field">
            <span>Lot Provided</span>
            <input type="checkbox" class="manufacturer-item-form-field-checkbox" 
                v-model="item.lotProvided"
                />
        </div>        
        <div class="manufacturer-item-form-field">
            <span>Serial Number Provided</span>
            <input type="checkbox" class="manufacturer-item-form-field-checkbox" 
                v-model="item.serialNumberProvided"
                />
        </div>        
    </div>    
</template>

<style scoped>
.manufacturer-item-form-content {
    display: flex;
    flex-direction: column;
}

.manufacturer-item-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.manufacturer-item-form-field span {
    width: 30%;
    text-align: left;
}

.manufacturer-item-form-field-checkbox {
    width: auto;    
}

.manufacturer-item-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./ManufacturerItemForm.js" />