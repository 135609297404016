<template>
    <div class="cdm-types-content">
        <div class="cdm-types-type" 
            :class="[index == selectedIndex ? 'cdm-types-selected' : '']"
            v-for="(type, index) in types"
            @click="selectType(type.type, index)"
            :key="index">{{type.name}}</div>
    </div>    
</template>

<style scoped>
.cdm-types-content {
    display: flex;
    flex-direction: column;
}

.cdm-types-type {
    text-align: left;
    padding: 0.5em;
    border: 1px black solid;
    cursor: pointer;
}

.cdm-types-selected {
    font-weight: bold;
}
</style>

<script lang="js" src="./CdmTypes.js" />