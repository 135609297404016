import ItemForm from "./ItemForm.vue";
import VendorItemForm from "./VendorItemForm.vue";
import ManufacturerItemForm from "./ManufacturerItemForm.vue";

export default {
    name: "CombinedItemForm",
    components: {
        ItemForm,
        VendorItemForm,
        ManufacturerItemForm
    },
    props: [
        "itemData"
    ],
    methods: {
        selectType(type, index) {
            this.selectedIndex = index;
            this.$emit('onCdmTypeSelected', type);
        }
    }
}