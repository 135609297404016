import ItemWarehouseForm from "./ItemWarehouseForm.vue"
import UomForm from "./UomForm.vue"

export default {
    name: "ItemForm",
    components: {
        ItemWarehouseForm,
        UomForm
    },
    props: [
        "item"
    ],
    methods: {
    }
}