
export default {
    name: "ItemWarehouseForm",
    components: {
    },
    props: [
        "warehouses"
    ],
    methods: {
    }
}