<template>
    <div class="item-form-content">
        <h3>Item details</h3>
        <div class="item-form-field">
            <span>Item Code</span>
            <input type="text" class="item-form-field-textbox"
                v-model="item.itemCode"
                />
        </div>
       <div class="item-form-field">
            <span>System Managed</span>
            <select
                class="item-form-field-select"
                v-model="item.systemManagedType"
                >
                <option disabled value="">Please Select One</option>
                <option value="TECSYS">TECSYS</option>
                <option value="ERP">ERP</option>
            </select>
        </div>
        <div class="item-form-field">
            <span>Ancillary Item Information</span>
            <input type="text" class="item-form-field-textbox" 
                v-model="item.ancillaryItemInformation"
                />
        </div>
        <div class="item-form-field">
            <span>License Verification Required</span>
            <input type="checkbox" class="item-form-field-checkbox" 
                v-model="item.licenseVerificationRequired"
                />
        </div>
        <div class="item-form-field">
            <span>Description</span>
            <input type="text" class="item-form-field-textbox" 
                v-model="item.itemDescription[0].shortDescription"
                />
        </div>
        <div class="item-form-field">
            <span>Clinical Tissue</span>
            <input type="checkbox" class="item-form-field-checkbox" 
                v-model="item.itemDescription[0].clinicalTissue"
                />
        </div>        
        <div class="item-form-field">
            <span>Lot Entry Required</span>
            <input type="checkbox" class="item-form-field-checkbox" 
                v-model="item.lotEntryRequired"
                />
        </div>  
        <UomForm title="Packaging Uoms" :uoms="item.packagingUom" />
        <UomForm title="Transaction Uoms" :uoms="item.transactionUom" />
        <ItemWarehouseForm :warehouses="item.warehouse"/>
    </div>    
</template>

<style scoped>
.item-form-content {
    display: flex;
    flex-direction: column;
}

.item-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.item-form-field span {
    width: 30%;
    text-align: left;
}

.item-form-field-checkbox {
    width: auto;    
}

.item-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./ItemForm.js" />