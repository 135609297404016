<template>
    <div class="location-form-content">
        <h4>Vendor Location</h4>
        <div class="location-form-field">
            <span>Email</span>
            <input type="text" class="location-form-field-textbox"
                v-model="locations[0].email"
                />
        </div>
        <div class="location-form-field">
            <span>Fax Number</span>
            <input type="text" class="location-form-field-textbox" 
                v-model="locations[0].faxNumber"
                />
        </div>
        <div class="location-form-field">
            <span>Phone Number 1</span>
            <input type="text" class="location-form-field-textbox"
                v-model="locations[0].phoneNumber1"
                />
        </div>
        <div class="location-form-field">
            <span>Phone Number 2</span>
            <input type="text" class="location-form-field-textbox" 
                v-model="locations[0].phoneNumber2"
                />
        </div>
        <div class="location-form-field">
            <span>Vendor Location Code</span>
            <input type="text" class="location-form-field-textbox" 
                v-model="locations[0].vendorLocationCode"
                />
        </div>
        <AddressForm :address="locations[0].address"/>
    </div>    
</template>

<style scoped>
.location-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.location-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.location-form-field span {
    width: 30%;
    text-align: left;
}

.location-form-field-checkbox {
    width: auto;    
}

.location-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./VendorLocationForm.js" />