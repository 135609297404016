import AddressForm from "./AddressForm.vue";
import OrganizationForm from "./OrganizationForm.vue";
import GlobalLocationNumberForm from "./GlobalLocationNumberForm.vue";

export default {
    name: "CustomerForm",
    components: {
        AddressForm,
        OrganizationForm,
        GlobalLocationNumberForm
    },
    props: [
        'customer'
    ],
    data() {
        return {
        };
    },
    methods: {
    }
}