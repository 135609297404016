import UomForm from "./UomForm.vue";

export default {
    name: "VendorItemForm",
    components: {
        UomForm
    },
    props: [
        "item"
    ],
    data() {
        return {
        };
    },
    methods: {
    }
}