<template>
    <div class="vendor-item-form-content">
        <h3>Vendor Item details</h3>
        <div class="vendor-item-form-field">
            <span>Vendor Code</span>
            <input type="text" class="vendor-item-form-field-textbox"
                v-model="item.vendorCode"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Vendor Item Code</span>
            <input type="text" class="vendor-item-form-field-textbox"
                v-model="item.vendorItemCode"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Item Code</span>
            <input type="text" class="vendor-item-form-field-textbox"
                v-model="item.itemCode"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Manufacturer Code</span>
            <input type="text" class="vendor-item-form-field-textbox"
                v-model="item.manufacturerCode"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Manufacturer Item Code</span>
            <input type="text" class="vendor-item-form-field-textbox"
                v-model="item.manufacturerItemCode"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Description</span>
            <input type="text" class="vendor-item-form-field-textbox" 
                v-model="item.description[0].shortDescription"
                />
        </div>
        <div class="vendor-item-form-field">
            <span>Supply Site Item</span>
            <input type="checkbox" class="vendor-item-form-field-checkbox" 
                v-model="item.supplySiteItem"
                />
        </div>        
        <UomForm title="Vendor UOMs" :uoms="item.vendorUom" />
    </div>    
</template>

<style scoped>
.vendor-item-form-content {
    display: flex;
    flex-direction: column;
}

.vendor-item-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.vendor-item-form-field span {
    width: 30%;
    text-align: left;
}

.vendor-item-form-field-checkbox {
    width: auto;    
}

.vendor-item-form-field-textbox {
    width: 50%;    
}

</style>
<script lang="js" src="./VendorItemForm.js" />