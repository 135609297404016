export default {
    name: "UomForm",
    components: {
    },
    props: [
        "title",
        "uoms"
    ],
    methods: {
    }
}