import OrganizationForm from './OrganizationForm.vue'
import VendorLocationForm from './VendorLocationForm.vue';

export default {
    name: "VendorForm",
    components: {
        OrganizationForm,
        VendorLocationForm,
    },
    props: [
        'vendor'
    ],
    data() {
        return {
        };
    },
    methods: {
    }
}