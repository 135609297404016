<template>
    <div class="combined-item-form-content">
        <ManufacturerItemForm :item="itemData.manufacturerItem"/>
        <ItemForm :item="itemData.item"/>
        <VendorItemForm :item="itemData.vendorItem"/>
    </div>
</template>

<style scoped>
.combined-item-form-content {
    display: flex;
    flex-direction: column;
}
</style>

<script lang="js" src="./CombinedItemForm.js" />