import { mapActions, mapGetters } from "vuex"
import CdmTypes from "../components/master-data-gen/CdmTypes.vue"
import VendorForm from "../components/master-data-gen/VendorForm.vue"
import ManufacturerForm from "../components/master-data-gen/ManufacturerForm.vue"
import CustomerForm from "../components/master-data-gen/CustomerForm.vue"
import CombinedItemForm from "../components/master-data-gen/CombinedItemForm.vue"

import {Vendor, Manufacturer, Customer, Item} from '../api/master-data';

export default {
    name: "MasterDataGenerator",
    components: {
        CdmTypes,
        VendorForm,
        ManufacturerForm,
        CustomerForm,
        CombinedItemForm
    },
    data() {
        return {
            newVendor: new Vendor(),
            newManufacturer: new Manufacturer(),
            newCustomer: new Customer(),
            newItem: new Item()
        }
    },
    computed: {
        ...mapGetters('masterDataGen', { selectedCdmType: 'getSelectedCdmType', status: 'getStatus', response: 'getGenResponse' }),
    },
    watch: {
        newItem: {
            handler(newVal) {
                this.syncItemFields(newVal);
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('masterDataGen', [
            'setSelectedCdmType',
            'createNewVendorCDM',
            'createNewManufacturerCDM',
            'createNewCustomerCDM',
            'createNewItemCDM',
        ]),
        loadCdmModel() {
            if (this.selectedCdmType === 'VENDOR') {
                this.createNewVendorCDM({vendorData: this.newVendor, generate: true});
            }
            else if (this.selectedCdmType === 'MANUFACTURER') {
                this.createNewManufacturerCDM({manufacturerData: this.newManufacturer, generate: true});
            }
            else if (this.selectedCdmType === 'CUSTOMER') {
                this.createNewCustomerCDM({customerData: this.newCustomer, generate: true});
            }
            else if (this.selectedCdmType === 'ITEM') {
                this.normalizeItemFields();
                this.createNewItemCDM({itemData: this.newItem, generate: true});
            }
        },
        syncItemFields(newVal) {
            this.newItem.vendorItem.manufacturerCode = newVal.manufacturerItem.manufacturerCode;
            this.newItem.vendorItem.manufacturerItemCode = newVal.manufacturerItem.manufacturerItemCode;
            this.newItem.vendorItem.itemCode = newVal.item.itemCode;
        },
        normalizeItemFields() {
            for (let i=0; i < this.newItem.item.transactionUom.length; i++) {
                this.newItem.item.transactionUom[i].sellingDefault = this.newItem.item.transactionUom[i].default;
            }

            for (let i=0; i < this.newItem.vendorItem.vendorUom.length; i++) {
                this.newItem.vendorItem.vendorUom[i].purchasingDefault = this.newItem.vendorItem.vendorUom[i].default;
                this.newItem.vendorItem.vendorUom[i].vendorUomCode = this.newItem.vendorItem.vendorUom[i].uomCode;
            }
        },
        onCdmTypeSelected(cdmType) {
            this.setSelectedCdmType(cdmType);
        }
    }

}