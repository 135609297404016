<template>
    <div class="warehouse-form-content">
        <h4>Warehouse details</h4>
        <div class="warehouse-form-field">
            <span>Warehouse Code</span>
            <input type="text" class="warehouse-form-field-textbox"
                v-model="warehouses[0].warehouseCode"
                />
        </div>
        <div class="warehouse-form-field">
            <span>Warehouse Type</span>
            <select
                class="warehouse-form-field-select"
                v-model="warehouses[0].warehouseType"
                >
                <option disabled value="">Please Select One</option>
                <option value="WAREHOUSE">Warehouse</option>
                <option value="HOSPITAL">Hospital</option>
                <option value="HOSPITAL_PHARMACY">Hospital Pharmacy</option>
            </select>
        </div>
        <div class="warehouse-form-field">
            <span>ABC Count Class</span>
            <input type="text" class="warehouse-form-field-textbox" 
                v-model="warehouses[0].abcCountClass"
                />
        </div>
        <div class="warehouse-form-field">
            <span>Consolidated Package Code</span>
            <input type="text" class="warehouse-form-field-textbox" 
                v-model="warehouses[0].consolidatedPackageCode"
                />
        </div>
        <div class="warehouse-form-field">
            <span>Supply Site Code</span>
            <input type="text" class="warehouse-form-field-textbox" 
                v-model="warehouses[0].supplySiteCode"
                />
        </div>
        <div class="warehouse-form-field">
            <span>Lot Entry Required</span>
            <input type="checkbox" class="warehouse-form-field-checkbox" 
                v-model="warehouses[0].lotEntryRequired"
                />
        </div>        
        <div class="warehouse-form-field">
            <span>Serial Number Required</span>
            <input type="checkbox" class="warehouse-form-field-checkbox" 
                v-model="warehouses[0].serialNumberRequired"
                />
        </div>        
        <div class="warehouse-form-field">
            <span>Supply Site Item</span>
            <input type="checkbox" class="warehouse-form-field-checkbox" 
                v-model="warehouses[0].supplySiteItem"
                />
        </div>        
        <div class="warehouse-form-field">
            <span>Tag Tracked</span>
            <input type="checkbox" class="warehouse-form-field-checkbox" 
                v-model="warehouses[0].tagTracked"
                />
        </div>        
    </div>    
</template>

<style scoped>
.warehouse-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.warehouse-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.warehouse-form-field span {
    width: 30%;
    text-align: left;
}

.warehouse-form-field-checkbox {
    width: auto;    
}

.warehouse-form-field-textbox {
    width: 35em;    
}

.warehouse-form-field-select {
    width: 35em;    
    padding: .2em;
}

</style>
<script lang="js" src="./ItemWarehouseForm.js" />